import React from "react"
import { graphql, PageProps } from "gatsby"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo/seo"
import { BlogSchema } from "../components/schema/BlogSchema"
import { GlobalFooter } from "../components/global-footer/GlobalFooter"
import { InternalPagination } from "../components/blog/InternalPagination"
import { BlogPostEdge } from "../components/blog/BlogBanner/blog-banner-types"
import { BlogPost } from "../components/blog/BlogPost/BlogPost"
import { BlogPostGraphQLData } from "../components/blog/BlogPost/blog-post-types"
import { Breadcrumb } from "components/breadcrumb/Breadcrumb"
import { StubBanner } from "components/form-stub/StubBanner"
import { useGeneralSettings } from "hooks/useGeneralSettings"

type PageContext = PageProps["pageContext"] & {
  parentSlug: string
  relatedArticles: BlogPostEdge[]
  noIndex: boolean
  prev?: BlogPostEdge["node"]
  next?: BlogPostEdge["node"]
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  slug: string
  linkedPagesUrlPathCodex: Record<string, string>
}

type PageDataNode = BlogPostGraphQLData & {
  global_footer: {
    value: any
  }
}

type PageDataElement = {
  elements: PageDataNode
}

type PageData = {
  allKontentItemBlogPost: {
    nodes: PageDataElement[]
  }
}

interface BlogPostProps extends PageProps {
  pageContext: PageContext
  data: PageData
}

const Blog: React.FC<BlogPostProps> = ({ location, data, pageContext }) => {
  const { slug } = pageContext
  const { pathname, hash } = location
  const [blogPost] = data?.allKontentItemBlogPost?.nodes
  const {
    heading,
    published_date,
    blog_post_content,
    seo_metadata__meta_title,
    seo_metadata__meta_description,
    seo_metadata__canonical_link,
    global_footer,
    featured_image,
  } = blogPost.elements
  const crumbLabel = heading.value

  const {
    breadcrumb,
    prev,
    next,
    parentSlug,
    relatedArticles,
    linkedPagesUrlPathCodex,
  } = pageContext
  const { globalFormStubBanner } = useGeneralSettings()
  return (
    <Layout
      location={slug}
      pathName={pathname}
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
    >
      <SEO
        title={seo_metadata__meta_title?.value ?? ""}
        description={seo_metadata__meta_description?.value ?? ""}
        canonical={seo_metadata__canonical_link?.value ?? ""}
        image={featured_image}
      />
      <BlogSchema
        heading={heading.value}
        description={seo_metadata__meta_description?.value ?? ""}
        publishedDate={published_date?.value ?? ""}
        image={featured_image.value[0]}
      />
      <Breadcrumb
        crumbs={breadcrumb.crumbs}
        crumbSeparator=" > "
        hiddenCrumbs={["/company", "/blog/posts", "/blog/post"]}
        crumbLabel={crumbLabel}
      />
      {globalFormStubBanner && Object.keys(globalFormStubBanner).length > 0 && (
        <StubBanner formStub={globalFormStubBanner} />
      )}
      {blog_post_content && (
        <BlogPost
          heading={heading?.value}
          content={blog_post_content}
          featuredImage={featured_image.value[0]}
        >
          <InternalPagination prev={prev} next={next} parentSlug={parentSlug} />
        </BlogPost>
      )}

      {global_footer?.value?.length > 0 && (
        <GlobalFooter
          content={global_footer}
          relatedArticles={relatedArticles}
        />
      )}
    </Layout>
  )
}

export const getPost = graphql`
  query getPost($slug: String!) {
    allKontentItemBlogPost(
      filter: { elements: { slug: { value: { eq: $slug } } } }
    ) {
      nodes {
        elements {
          seo_metadata__meta_title {
            value
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__canonical_link {
            value
          }
          ...BlogPostFragment
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
        }
      }
    }
  }
`
export default Blog
